.container {
    @extend %container;
}

main {
    position: relative;
}

main, footer {
    width: 75%;
    margin-left: 25%;;
}

#sprite-svg {
    @extend %visuallyhidden;
}

// sitemap 
.menu__nav__list .nav-drop.container, .menu__nav__list .nav-drop .nav-grandchild.container {
    margin: 0;
}

// When menu open or GTranslate open
body {
    &::after {
        content: '';
        @include fixed($top: 0, $left: 0, $right: 0, $bottom: 0);
        width: 100%;
        opacity: 0;
        background: #00000080;
        z-index: 10;
        transition: opacity $duration ease-in-out;
        transition-delay: 0s;
        pointer-events: none;
    }
}

body.js-active-navmain {
    // overflow: hidden;
    transition-delay: .3s;

    &::after {
        opacity: 1;
        transition-delay: 0s;
        pointer-events: auto;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1500
@media screen and (max-width: 1500px) {

    main, footer  {
        width: calc(100% - 300px);
        margin-left: 300px;
    }

}


// 1040
@media screen and (max-width: $medium) {

    main, footer  {
        width: inherit;
        margin-left: inherit;
    }

    body.js-active-responsive {
        overflow: hidden;
    }

}
