.home {

    //======================================================================================================
    // Style Home
    //======================================================================================================
    &__title {
        display: inline-flex;
        align-items: center;
        gap: 10px;
        margin: 0;
        font-size: $font-size--1;
        font-weight: $font-weight-black;
        color: $color-text;
        text-transform: uppercase;
        letter-spacing: 0.05em;

        &::before {
            content: '';
            display: block;
            @include size(50px, 1px);
            background-color: $color-text;
        }
    }

    &__button {
        margin: 0;
        padding: 10px 30px;
        gap: 10px;

        svg {
            @include size(20px);
        }
    }


    //======================================================================================================
    // SlideShow
    //======================================================================================================
    &-slideshow {
        position: relative;

        > .container {
            position: relative;
        }

        .slideshow {
            @include size(100%, 750px);

            img, video {
                position: absolute;
                object-fit: cover;
                object-position: top;
                @include size(100%);
            }

            &__container {
                display: inherit;
                position: relative;
                @include size(100%);
            }

            .container--content {
                @include flex($justifyContent: flex-end);
            }

            &__content {
                @include flex($direction: column);
                gap: 10px;
                box-sizing: border-box;
                @include absolute($bottom: 80px);
                @include size(500px, auto);
                padding: 25px 0 25px 30px;
                background-color: $color-dark;
                color: $color-caption;

                &::after {
                    content: '';
                    @include absolute($top: 0, $bottom: 0, $right: -50vw);
                    z-index: 0;
                    display: block;
                    width: 52vw;
                    background-color: $color-dark;
                }
            }

            &__title {
                position: relative;
                z-index: 1;
                margin: 0;
                font-family: $font-family--heading;
                font-size: $font-size--4;
                font-weight: $font-weight-semibold;
            }

            &__intro {
                position: relative;
                z-index: 1;
            }

            &--video {
                img {
                    display: none;
                }
            }

            // Pagination (bullet)
            &__pagination {
                @include absolute($bottom: 30px, $right: 50px);
                @include flex($alignItems: center, $justifyContent: flex-end);
                gap: 10px;
                z-index: 2;
                width: auto;
        
                .swiper-pagination-bullet {
                    @include size(10px);
                    background-color: $color-white;
                    border-radius: 50%;
                    opacity: 1;
                    transition: background-color $duration $timing;
        
                    &:hover, &:focus {
                        background-color: $color-main;
                    }
        
                    &-active {
                        background-color: $color-main;
                    }
                }
            }
        
        }
    }


    //======================================================================================================
    // Brands
    //======================================================================================================
    .brands {
        @include flex($direction: column);
        gap: 30px;
        margin-top: 50px;
        padding: 50px 0;

        &__list {
            @include flex($wrap: wrap);
            gap: 35px;
        }

        &__item {
            width: calc((100% / 3) - ((35px * 2) / 3));

            &:nth-child(4), &:last-child {
                width: calc((100% / 2) - (35px / 2));
            }
        }

        &__link {
            position: relative;
            @include flex($direction: column, $justifyContent: center);
            gap: 10px;
            height: 100%;
            padding: 30px 25px;
            background-color: #DBDEE0;
            transition: background-color $duration $timing;

            &:hover, &:focus {
                background-color: #A4A6A8;
                color: $color-text;

                .brands__icon {
                    opacity: 1;
                    z-index: 1;
                    transform: translate(0, 0);
                    transition: {
                        property: transform, opacity;
                        duration: $duration, .3s;
                        timing-function: $timing;
                    }
                }
            }
        }

        svg.brands__icon {
            box-sizing: content-box;
            @include absolute($top: 0, $right: 0);
            @include size(15px);
            padding: 10px;
            background-color: $color-white;
            opacity: 0;
            // transition: opacity $duration $timing;
            transform: translate(40px, -40px);
            transform-origin: top right;
            transition: {
                property: transform, opacity;
                duration: $duration, .12s;
                timing-function: $timing;
            }
        }
    }


    //======================================================================================================
    // News
    //======================================================================================================
    .news {
        position: relative;
        @include flex($direction: column);
        gap: 30px;
        padding: 50px 0;

        &__buttons {
            @include absolute($top: calc(50px + 30px), $right: 0);
            @include flex($alignItems: center, $justifyContent: flex-end);
            gap: 20px;
        }

        &__navigation {
            @include flex($alignItems: center);
            gap: 10px;

            &__button {
                @include size(40px);
                background-color: $color-dark;
                border-color: $color-dark;
                margin: 0;
                padding: 0;

                svg {
                    @include size(40px);
                    fill: $color-white;
                }

                &:hover, &:focus {
                    border-color: $color-dark;
                    background-color: $color-bg--transparent;

                    svg {
                        fill: $color-text;
                    }
                }
            }

            &--no-slide {
                display: none;
            }
        }

        &__list {
            min-height: 485px; // for transition margin

            &--no-slide {
                display: flex;
                gap: 30px;
            }
        }

        &__item {
            margin-top: 0 !important;
            transition: margin .35s $timing !important;

            &.swiper-slide-active + .news__item {
                margin-top: 40px !important;
            }

            &--no-slide {
                width: calc(50% - (30px / 2));

                &:last-child {
                    margin-top: 40px !important;
                }

                &:first-child {
                    margin-top: 0 !important;
                }
            }
        }

        &__link {
            @include flex($direction: column);
            gap: 15px;
            color: $color-text;

            &:hover, &:focus {
                .news__content {
                    img {
                        transform: scale(1.03);
                    }
                    
                    &__icon {
                        opacity: 1;
                        z-index: 1;
                        transform: translate(0, 0);
                        transition: {
                            property: transform, opacity;
                            duration: $duration, .5s;
                            timing-function: $timing;
                        }
                    }
                }
            }
        }

        &__content {
            position: relative;
            overflow: hidden;
            line-height: 0;
            @include size(100%, 280px);

            &--notimg {
                background: url($urlShort+'posts-icons/post.svg') no-repeat $color-bg--image;
                background-position: center;
            }

            img {
                @include size(100%);
                object-fit: cover;
                object-position: top center;
                transform: scale(1);
                transition: transform ease-in-out $duration;
            }

            &__icon {
                box-sizing: content-box;
                @include absolute($top: 0, $right: 0);
                @include size(20px);
                padding: 7.5px; // total width/height 35px
                background-color: $color-white;
                opacity: 0;
                transform: translate(40px, -40px);
                transform-origin: top right;
                transition: {
                    property: transform, opacity;
                    duration: $duration, .12s;
                    timing-function: $timing;
                }
            }
        }

        &__infos {
            @include flex($direction: column, $alignItems: flex-start);
            gap: 5px;

            &__title {
                margin: 0;
                @include font-size(28);
                line-height: 1.1
            }

            &__intro {
                @include font-size(18);
            }
        }
    }

    //======================================================================================================
    // Social
    //======================================================================================================
    .social {
        position: relative;
        @include flex($direction: column);
        gap: 30px;
        padding: 50px 0 100px 0;

        &__btn-more {
            @include absolute($bottom: 0, $left: 50%);
            transform: translateX(-50%);
            @include flex($alignItems: center, $justifyContent: center);
            @include size(40px);
            margin: 0 0 -20px 0;
            padding: 0;
            background-color: $color-main;
            border: 1px solid $color-main;
            border-radius: $border-radius--round;

            svg {
                @include size(20px);
                fill: $color-white;
                transition: {
                    property: background-color, transform;
                    duration: $duration;
                    timing-function: $timing;
                }
            }

            &:hover, &:focus {
                background-color: $color-white;
                border-color: $color-main;
                
                svg {
                    fill: $color-text;
                }
            }

            &.js-open {
                svg {
                    transform: rotate(45deg);
                }
            }
        }

        &__wrapper {
            height: 250px;
            overflow: hidden;
            transition: .45s ease-in-out;
                    
            &.js-open {
                height: 530px;
            }

            .ff-header, .ff-loadmore-wrapper {
                display: none !important;
            }

            .ff-stream {
                background-color: $color-white !important;
                padding: 0 !important;
            }

            #ff-stream-1 {
                .ff-item-cont {
                    height: auto !important;
                }

                .ff-item .ff-img-holder {
                    height: 250px !important;
                }
            }

            .ff-stream-wrapper {
                padding: 0 !important;
                margin: 0 !important;
                z-index: 9;
                display: flex;
                flex-wrap: wrap;
                gap: 30px;
            }

            .ff-item {
                float: none !important;
                position: unset !important;
                width: calc(50% - 15px) !important;
                transform: none !important;
            }

            // icon social feed
            .ff-label-wrapper {
                right: unset !important;
                left: 0 !important;
                @include size(50px !important);
                background-color: $color-main;

                .ff-icon {
                    @include flex($alignItems: center, $justifyContent: center);
                    @include size(50px !important);

                    &::before {
                        display: none;
                    }
                }

                .ff-icon-inner {
                    right: unset !important;
                    left: unset !important;
                    top: unset !important;
                    bottom: unset !important;
                    line-height: 3 !important;
                    font-size: 1rem !important;
                } 
            }    
            
            .ff-item-bar {
                padding: 0 !important;
                margin: 0 !important;
                width: 40% !important;
                position: absolute !important;
                bottom: 30px !important;
                right: 20px !important;
                height: auto !important;
                padding-top: 10px !important;
                border-top: 0 !important;

                &::after {
                    content: '';
                    @include absolute($top: 0, $left: 0);
                    display: block;
                    @include size(50px, 1px);
                    background-color: $color-dark;
                }
            }

            .ff-item-meta, .ff-content {
                margin: 0 0 0 20px;
                width: 40%;
                float: left;
            }

            .ff-content {
                margin-top: 10px;
                max-height: 100px;
            }

            .ff-item-meta {
                margin-top: 30px;
                font-style: italic;

                .ff-nickname, .ff-timestamp {
                    display: none;
                }
            }

            .ff-img-holder {
                float: left;
                width: 50%;

                img {
                    @include size(100%);
                    object-fit: cover;
                    object-position: top center;
                }
            }

            .picture-item__inner {
                box-shadow: none;
                background-color: $color-bg--neutral;

                &::before {
                    transition: background $duration $timing;
                }
            }

            .ff-upic-round .ff-img-holder:first-child, .ff-upic-round .ff-img-holder:first-child img, .ff-upic-round .picture-item__inner {
                border-radius: 0 !important;
            }
        }

        &__button {
            @include absolute($top: 50px, $right: 0);
            background-color: $color-dark;
            border-color: $color-dark;

            &:hover, &:focus {
                background-color: $color-bg--transparent;
                border-color: $color-dark;

                svg {
                    fill: $color-text;
                }
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1500 
@media screen and (max-width: 1500px) {

    .home  {

        //======================================================================================================
        // Style Home
        &__button {
            padding: 10px 25px;
        }

        //======================================================================================================
        // SlideShow
        &-slideshow {
            .slideshow {
                height: 600px;
            }
        }

        //======================================================================================================
        // Brands
        .brands {
            margin-top: 30px;

            &__list {
                gap: 30px;
            }
        
        }

        //======================================================================================================
        // News
        .news {
            &__content {
                height: 250px;
            }

            &__list {
                min-height: 455px; // for transition margin
            }
        }

        //======================================================================================================
        // Social
        .social {
            padding-bottom: 80px;
        }

    }
}

// 1360
@media screen and (max-width: 1360px) {

    .home  {

        //======================================================================================================
        // Style Home
        &__title {
            @include font-size(30);

            &::before {
                width: 30px;
            }
        }

        &__button {
            padding: 10px 17px;
        }

        //======================================================================================================
        // SlideShow
        &-slideshow {
            .slideshow {
                height: 450px;
                
                &__content {
                    bottom: 60px;
                    width: 365px;
                    padding: 20px 0 20px 25px;
                }
    
                &__title {
                    @include font-size(20);
                }
    
                &__intro {
                    @include font-size(14);
                }
            }

        }

        //======================================================================================================
        // Brands
        .brands {
            margin-top: 20px;
            padding: 40px 0;

            &__list {
                gap: 20px;
            }

            &__item {
                width: calc((100% / 3) - ((20px * 2) / 3));
    
                &:nth-child(4), &:last-child {
                    width: calc((100% / 2) - (20px / 2));
                }
            }

            &__link {
                @include flex($direction: column, $justifyContent: flex-start);
                gap: 10px;
                padding: 20px;

                svg {
                    @include size(fit-content, 30px);
                }
            }

            &__text {
                @include font-size(14);
            }
        }

        //======================================================================================================
        // News
        .news {
            padding: 40px 0;

            &__buttons {
                gap: 10px;
            }

            &__navigation {
                gap: 5px;
            }

            &__list {
                min-height: 375px; // for transition margin

                &--no-slide {
                    gap: 20px;
                }
            }

            &__item {
                &--no-slide {
                    width: calc(50% - (20px / 2));
                }
            }

            &__content {
                height: 180px;
            }

            &__infos {
                &__title {
                    @include font-size(22);
                }
    
                &__intro {
                    @include font-size(14);
                }
            }
        }

        //======================================================================================================
        // Social
        .social {
            padding: 40px 0 80px;

            &__wrapper {
                height: 420px;

                &.js-open {
                    height: 890px;
                }

                #ff-stream-1 .ff-item .ff-img-holder {
                    height: 190px !important;
                }

                #ff-stream-1 .ff-item-cont {
                    height: 420px !important;
                }

                .ff-img-holder {
                    float: none;
                    width: 100%;
                }

                .ff-content, .ff-item-meta {
                    float: none;
                    width: auto;
                    margin: 0 20px;
                }
                
                .ff-item-meta {
                    margin-top: 20px;
                }

                .ff-content {
                    // max-height: none !important;
                    margin-top: 10px;
                }

                .ff-item-bar {
                    width: calc(100% - 40px) !important;
                    float: none !important;
                    right: 0 !important;
                    margin: 0 20px !important;
                }
            }

            &__button {
                top: 40px;
            }
        }
    }
    
}

// 1040
@media screen and (max-width: $medium) {

    .home  {

        //======================================================================================================
        // SlideShow
        &-slideshow {    
            .slideshow {
                &--video {
                    video {
                        display: none;
                    }
    
                    img {
                        display: block;
                    }
                }
            }
        }

        //======================================================================================================
        // News
        .news {
            &__list {
                min-height: 335px; // for transition margin
            }
        }

    }

}



// 640
@media screen and (max-width: $small) {

    .home {

        // Style Home
        //======================================================================================================
        &__title {
            @include font-size(25);
        }

        //======================================================================================================
        // SlideShow
        &-slideshow {
            .slideshow {
                height: auto;

                &__container, .swiper-slide, .swiper-wrapper {
                    height: auto;
                }

                img {
                    position: relative;
                    height: 280px;
                }

                .container--content {
                    margin-top: -46px;
                    position: relative;
                }

                &__content {
                    position: relative;
                    width: 100%;
                    bottom: auto;
                }

                &__pagination {
                    bottom: auto;
                    top: 215px;
                }
            }
        }

        //======================================================================================================
        // Brands
        .brands {
            &__item {
                width: 100%;

                &:nth-child(4), &:last-child {
                    width: 100%;
                }
            }
        }

        //======================================================================================================
        // News
        .news {
            &__buttons {
                order: 3;
                position: unset;
                justify-content: flex-start;
            }

            &__navigation { 
                &--no-slide {
                    display: flex;
                }
            }

            &__wrapper {
                margin: 0 -10px;
                padding: 0 10px;
            }

            &__list {
                min-height: unset;

                &--no-slide {
                    gap: 0;
                }
            }

            &__item {
                transition: none !important;

                &--no-slide {    
                    &:last-child, &:first-child {
                        margin-top: 0 !important;
                    }
                }

                &--alone {
                    width: 100%;
                }

                &.swiper-slide-active + .news__item {
                    margin-top: 0 !important;
                }
            }
        }

        //======================================================================================================
        // Social
        .social {
            &__button {
                position: unset;
                right: unset;
                top: unset;
                width: fit-content;
                order: 3;
            }

            &__wrapper {
                .ff-item {
                    width: 100% !important;
                }
            }
        }

    }

}


// 360
@media screen and (max-width: 360px) {

    .home {

        //======================================================================================================
        // News
        .news {
            &__buttons {
                flex-wrap: wrap-reverse;
            }
        }

    }

}
