.header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 15;
    height: 100vh;
    width: 25%;
    background-color: $color-bg--neutral;

    &::after {
        content: '';
        display: block;
        @include absolute($top: 0, $right: 300px);
        @include size(1px, 100vh);
        background-color: rgba($color-dark, 0.2);;
    }

    &__btn {
        @include flex($alignItems: center, $justifyContent: center);
        gap: 10px;
        min-width: 200px;
        height: 40px;
        margin: 0;

        svg {
            @include size(20px);
        }
    }
}

.container--header {
    max-width: 300px;
    margin-left: auto;
    padding: 20px 30px;
    position: relative;
}

.admin-bar .container--header {
    padding-top: 32px;
}


//======================================================================================================
// Identity
//======================================================================================================
.identity {
    @include flex($alignItems: center, $justifyContent: flex-start);
    width: fit-content;
    height: auto;
    line-height: 0;
    margin: 30px 0;

    &__title {
        display: block;
        margin: 0;
        line-height: 0;
    }

    svg {
        @include size(200px, 55px);
    }

    &:hover, &:focus {
        svg {
            fill: darken($color-main, 15%);
        }
    }
}


//======================================================================================================
// Tools
//======================================================================================================
.tools {
    position: relative;
    @include flex($justifyContent: flex-start);
    column-gap: 15px;
    width: 100%;
    margin: 40px 0 20px 0;

    &__item {
        @include flex($alignItems: center, $justifyContent: center);
        @include size(40px);
        margin: 0;
        padding: 0;
        background-color: $color-dark;
        border: $border-width $border-style $color-dark;
        border-radius: $border-radius--round;
        cursor: pointer;

        svg {
            @include size(inherit);
            fill: $color-white;
        }

        &:hover, &:focus {
            background-color: $color-bg--transparent;
            transition-delay: inherit;
            border-color: $color-dark;
        }

        &--search {
            &--disabled {
                pointer-events: none;
                opacity: .2;
            }
        }

        &--close {
            @include size(30px);
            background-color: $color-white;
            border: $border-width $border-style $color-white;

            svg {
                fill: $color-text;
            }

            &:hover, &:focus {
                background-color: $color-bg--transparent;
                border-color: $color-white;

                svg {
                    fill: $color-white;
                }
            }
        }
    
    }

}

.gtranslate_wrapper {
    position: fixed;
    left: 25%;
    bottom: 0;
    display: none;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 75%;
    height: 120px;
    padding: 15px;
    background: $color-main;

    &.open {
        display: flex;
    }

    a.glink {
        position: relative;
        font-family: $font-family--heading;
        font-size: $font-size--text;
        font-weight: $font-weight-semibold;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: $color-white;
        text-decoration: underline;
        text-decoration-color: transparent;
        transition: text-decoration $duration $timing;

        &:hover, &:focus {
            color: $color-white;
            text-decoration-color: $color-white;
        }

        &.gt-current-lang {
            font-weight: $font-weight-medium;
            text-decoration-color: $color-white;
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1500
@media screen and (max-width: 1500px) {

    .header {
        width: 300px;
    }


    .gtranslate_wrapper {
        left: 300px;
        width: calc(100% - 300px);
    }
    
}


// 1040
@media screen and (max-width: $medium) {

    .header {
        @include size(auto);
        position: relative;
        left: inherit;
        top: inherit;
        padding: inherit;
        background-color: inherit;

        &::after { 
            display: none;
        }

        .header__btn {
            padding: 10px 25px;
            min-width: inherit;
        }
    }

    .container--header {
        @include fixed($bottom: 0, $left: 0, $right: 0,);
        @include flex($alignItems: center, $justifyContent: flex-start);
        max-width: 90%;
        margin: 0 auto;
        padding: 0 ($gl-gutter-sm * 0.5);
        z-index: 30;
        column-gap: 5px;
        @include size(auto, 80px);
        background-color: $color-bg--neutral;
        

        &::before {
            content: '';
            display: block;
            @include absolute($top: 0, $left: -50vw, $right: -50vw);
            z-index: -1;
            height: 100%;
            background-color: $color-bg--neutral;
        }
    }

    .search-popup {
        z-index: 31;
    }

    .identity {
        @include absolute($top: 0, $left: 50%);
        @include flex($alignItems: center, $justifyContent: center);
        @include size(195px, 80px);
        transform: translateX(-50%);
        margin: 0;
        background-color: $color-white;

        svg {
            @include size(145px, 40px);
        }
    }

    .tools {
        margin: 0 0 0 auto;
        column-gap: 5px;
        width: inherit;
    }

    .gtranslate_wrapper {
        bottom: 80px;
        left: 0;
        width: 100%;
        height: auto;
        justify-content: flex-start;
        padding: 35px 15% 35px 35px;
        gap: 20px;
    }

    .tools__item--close {
        height: 30px;
        width: 30px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5%;
    }

    .admin-bar .container--header {
        padding-top: 0;
    }

}


// 640
@media screen and (max-width: $small) {

    .header .header__btn {
        @include size(40px);
        padding: 0;

        span {
            display: none;
        }
    }

}

