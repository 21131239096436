.nav-main {
    padding: 20px 0;

    // Main menu
    .menu {
        @include flex($direction: column, $wrap: wrap, $alignItems: flex-start, $justifyContent: space-between);
        transition: $duration ease-out;

        &__item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            &.menu-item-has-children {
                .menu__link {
                    &:hover, &:focus {
                        &::after {
                            color: $color-white;
                        }
                    }
                }

                &.js-active-submenu {

                    .submenu {
                        transform: scaleX(1);
                        transition-delay: 0s;

                        &__item {
                            opacity: 1;
                            transition-delay: .2s;
                        }
                    }
                }
            }

            &:first-child {
                margin-bottom: 15px;

                .menu__link {
                    justify-content: flex-start;
                    gap: 10px;
                    width: fit-content;
                    padding: 15px 20px;
                    border: 1px solid $color-dark;
                    transition: {
                        property: border-color, color;
                        duration: $duration;
                        timing-function: $timing;
                    }

                    &::before {
                        display: none;
                    }

                    &:hover, &:focus {
                        border-color: $color-main;
                    }
                }
            }
        }

        &__link {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 25px 0;
            font-family: $font-family--heading;
            font-size: $btn-font-size;
            font-weight: $font-weight-normal;
            color: $color-text;
            letter-spacing: 0.05em;
            line-height: 1.1;
            text-transform: uppercase;

            &::before {
                content: "";
                @include absolute($bottom: 0, $left: 0);
                @include size(50px, 1px);
                background-color: $color-dark;
                transition: {
                    property: background-color, width;
                    duration: $duration;
                    timing-function: $timing;
                }
            }

            &__icon {
                @include size(10px);
                fill: $color-text;
                transition: fill $duration $timing;
            }
    
            &:hover, 
            &:focus {
                color: $color-main;

                .menu__link__icon {
                    fill: $color-main;
                }

                &::before {
                    width: 80px;
                    background-color: $color-main;
                }
            }
        }
    }

    // Sub menu
    .submenu {
        display: block;
        @include absolute($top: 0, $right: inherit, $left: 100%);
        z-index: inherit;
        width: 300px;
        height: 100vh;
        padding: 175px 40px 40px 40px;
        background-color: $color-dark;
        box-shadow: none;
        transform: scaleX(0);
        transform-origin: center left;
        transition-delay: .3s;
        transition: transform .3s $timing .3s;
        overflow-x: hidden;
        overflow-y: auto;

        &__nav {
            @include flex($direction: column, $justifyContent: flex-start, $alignItems: flex-start);
            row-gap: 5px;
            max-width: inherit;
            max-height: 100vh;
            padding: 0;
        }

        &__item {
            width: auto;
            margin: 0;  
            opacity: 0;
            transition: opacity $duration $timing;
            transition-delay: .1s;
        }

        &__link {
            position: relative;
            display: flex;
            align-items: center;
            width: auto;
            height: auto;
            margin-left: -20px;
            padding: 10px 10px 10px 30px;
            color: $color-white;
            font-weight: $font-weight-normal;
            transform: translateX(0);
            transform-origin: left;
            transition: all $duration ease-in-out;
            letter-spacing: .05em;

            &::before {
                content: '';
                @include absolute($top: 50%, $left: 0);
                display: block;
                @include size(20px, 1px);
                background-color: $color-white;
                opacity: 0;
                transform: scaleX(0) translateX(20px);
                transform-origin: left;
                transition: all $duration ease-in-out;
                pointer-events: none;
            }

            &:hover, &:focus {
                transform: translateX(30px);

                &::before {
                    opacity: 1;
                    transform: scaleX(1) translateX(0px);
                }
            }

            &--grandchild {
                display: flex;
                margin-left: -20px;
                padding: 10px 10px 10px 30px;
                border-bottom: 0;
                font-weight: $font-weight;
                text-transform: initial;
                line-height: initial;

                &::before {
                    display: none;
                }
            }
        }
    }
    
    // For responsive
    &__button, &__icon, &__close, .submenu__close {
        display: none;
    }

}


.admin-bar .nav-main .submenu {
    padding-top: 200px;
}


//=================================================================================================
// Responsive
//=================================================================================================

//  + 1040 et - de 800px
@media screen and (min-width: 1040px) and (max-height: 865px) { 
    
    .nav-main {
        .menu__link {
            padding: 16px 0;
        }
    }

}


// 1040
@media screen and (max-width: $medium) {

    // if menu open (no scroll on body)
    body.js-active-navmain {
        overflow: hidden;
    }    

    .nav-main {
        position: relative;
        padding: 0;

        // If menu open
        &.js-active-menu {
            
            // Container menu
            .nav-main__container {
                left: 0;
            }
        }

        // Container menu
        &__container {
            position: fixed;
            top: 0;
            left: -100vw;
            right: 100%;
            z-index: 990;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            row-gap: 15px;
            max-width: inherit;
            width: 100%;
            height: 100vh;
            background-color: $color-dark;
            padding: 40px 0;
            transition: left .35s $timing;
        }
        
        // Burger menu responsive - Open menu
        &__button {
            position: relative;
            @include flex($justifyContent: flex-start, $alignItems: center);
            column-gap: 5px;
            margin: 0;
            padding: 10px 15px;
            background-color: transparent;
            border: 1px solid $color-dark;
            border-radius: 0;
            font-size: $font-size--text-small;
            font-weight: $font-weight-medium;
            letter-spacing: 0.05em; 
            color: $color-text;
            text-transform: uppercase;
            transition: $duration;
            cursor: pointer;

            &:hover, &:focus {
                color: $color-text;
                border-color: $color-dark;
            }
        }

        // Button icon (Burger menu responsive - Open menu)
        svg {
            @include size(20px);
            fill: $color-text;
        }

        // Burger menu responsive - Close menu
        &__close {
            position: relative;
            z-index: 10;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            min-height: 40px;
            margin: 0 40px 0 auto;
            padding: 10px;
            background-color: transparent;
            border: 1px solid $color-white;
            border-radius: $border-radius--round;
            transition: all $duration ease-in-out;

            svg {
                width: 12px;
                height: 12px;
                margin: 0;
                fill: $color-white;
                transition: all $duration ease-in-out;
            }

            &:hover,
            &:focus {
                background-color: $color-white;
                border-color: $color-white;

                svg {
                    fill: $color-dark;
                }
            }
        }

        // Main menu
        .menu {
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: center;
            overflow-y: auto;
            width: 100%;
            padding: 0 40px 40px 40px;

            &__item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 60% !important;
                border-bottom: 1px solid rgba($color-gray , 0.3);
    
                &.menu-item-has-children {
    
                    &:focus-within .submenu, 
                    &:hover .submenu {
                        justify-content: flex-start;
                    }
    
                    // If sub menu open
                    &.js-active-submenu {   
                        .submenu {
                            right: 0;
                            transition: all .3s $timing;

                            &__item {
                                opacity: 1;
                                transition-duration: inherit;
                                transition-timing-function: inherit;
                                transition-delay: inherit !important;
                            }
                        }
                    }
                }
    
                &:hover, &:focus {
                    background-color: $color-bg--transparent;
    
                    .menu__link {
                        color: $color-white;
                    }
                }

                &:first-child {
                    margin-bottom: 0;

                    .menu__link {
                        width: 100%;
                        border: 0;
                        padding: 20px 0;
                        justify-content: space-between;
                    }
                }
            }

            &__link {
                max-width: inherit;
                width: 100%;
                height: auto;
                padding: 20px 0;
                font-family: $font-family;
                font-size: $font-size--text;
                font-weight: $font-weight-medium;
                letter-spacing: 0.05em;
                color: $color-white;
                text-transform: uppercase;

                &::before {
                    display: none;
                }

                &::after {
                    display: none;
                }

                &__icon {
                    @include size(15px);
                    fill: $color-white;
                }

                &:hover, &:focus {
                    background-color: $color-bg--transparent;
                    color: $color-white;

                    .menu__link__icon {
                        fill: $color-white;
                    }
                }
            }
        }

        // Sub menu
        .submenu {
            left: inherit;
            top: 0;
            right: 100%;
            z-index: 1;
            display: flex;
            flex-direction: column;
            row-gap: 30px;
            width: 100vw;
            height: 100vh;
            height: 100dvh;
            padding: 40px 0 60px 0;
            background-color: $color-dark;
            transform: scaleX(1);
            transition: all .3s $timing;

            &::after {
                display: none;
            }

            // Burger menu responsive - Close submenu
            &__close {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                width: 40px;
                height: 40px;
                margin: 0 0 0 40px;
                padding: 0;
                background-color: transparent;
                border: 1px solid $color-white;
                border-radius: $border-radius--round;
                transition: $duration;

                &:hover,
                &:focus {
                    background-color: $color-white;
                    border-color: $color-white;

                    svg {
                        stroke: $color-dark;
                    }
                }

                svg {
                    width: 40px;
                    height: 40px;
                    fill: none;
                    stroke: $color-white;
                    stroke-width: 2px;
                    transition: $duration;
                }
            }

            &__nav {
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                max-width: 100%;
                padding: 0 40px 40px 40px;
                overflow-y: auto;
                overflow-x: hidden;
                height: auto;
            }

            &__item {
                width: 60%;
                margin: auto; 
            }

            &__link {
                color: $color-white;
                margin-left: 0;
                padding: 10px 0;

                &:hover, &:focus {
                    transition: none;
                    transform: none;
                }

                &::before {
                    display: none;
                }
            }
        }
    }

    // Admin 
    .admin-bar {
        .nav-main {
            &__container, .submenu {
                padding-top: 70px;
            }
        }
    }


}


// 640
@media screen and (max-width: $small) {

    .nav-main {
        &__button {
            margin: 0;
        }

        .menu__item, .submenu__item {
            width: 90%!important;
        }
    }

}
