.scrollTopBtn {
    @include fixed($right: 20px, $bottom: 20px);
    z-index: 10;
    @include size(40px);
    padding: 0;
    margin: 0;
    background-color: $color-dark;
    border-color: $color-white;
    border-radius: $border-radius--round;
    opacity: 0;
    transform: translateY(100px);
    transition: {
        property: opacity, transform, background-color, border-color;
        duration: .5s, .5s, $duration, $duration;
        timing-function: $timing;
    }

    &--show,
    &--show:focus  {
        opacity: 1;
        transform: translateY(0);
    }

    svg {
        fill: $color-white;
        transition: fill $duration $timing;
    }

    &:hover,
    &:focus {
        background-color: $color-white;
        border-color: $color-dark;

        svg {
            fill: $color-text;
        }
    }
}