.cover {
    position: relative;

    &__image-wrapper {
        width: 100%;
        height: 600px;
        background-color: $color-bg--image;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__informations {
        background-color: $color-white;
        padding: 60px 0;
    }

    &__title {
        display: inline-flex;
        align-items: center;
        gap: 10px;
        margin: 0 0 10px;
        color: $color-text;
        text-transform: uppercase;
        letter-spacing: 0.05em;

        &::before {
            content: '';
            display: block;
            @include size(50px, 1px);
            background-color: $color-text;
        }
    }

    &__lead-wrapper {
        p {
            margin: 20px 0 0;
            font-weight: $font-weight-semibold;
            font-size: $font-size--6;
        }
    }

    &__intro {
        color: $color-text;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1040
@media screen and (max-width: $medium) {

    .cover {
        &__image-wrapper {
            height: 450px;
        }

        &__informations {
            padding: 30px 0;
        }

        &__title {
            @include font-size(30);

            &::before {
                width: 30px;
            }
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .cover {
        &__lead-wrapper {
            p {
                font-weight: $font-weight;
            }
        }
        
        &__image-wrapper {
            height: 280px;
        }

        &__title {
            @include font-size(25);

            &::before {
                display: none;
            }
        }
    }

}
