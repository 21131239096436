.container .page-pagination {
    margin: 0 auto 30px;
}

.page-pagination {
    @extend %container;
    text-align: center;
    margin-bottom: 30px;

    p {
        margin-top: 15px;
        margin-bottom: 10px;
        font-family: $btn-font-family;
        font-size: $btn-font-size;
        font-weight: $btn-font-weight;
        color: $color-text;
        text-transform: uppercase;
    }
}

// For archive button see more post
button#load-older-posts {
    margin: 0;
    
    transition: {
        property: background-color, color, border-color, opacity;
        duration: $duration;
        timing-function: $timing;
    }
}


//======================================================================================================
// Responsive 
//======================================================================================================

// 1040
@media screen and (max-width: $medium) {

    .page-pagination {     
        margin-top: 15px;
    } 

} 
