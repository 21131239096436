//-------------------------------------------------------------------- //
// Document category
//-------------------------------------------------------------------- //
.card {
    &--category {
        margin: 20px 0;
    }

    &__content {
        &--category {
            display: flex;
            align-items: center;
            flex-direction: row;
            padding: 0;
    
            .card__content {
                padding-left: 20px;
            }
    
            h3 {
                margin-top: 0;
                margin-bottom: 0;
                color: $color--card;
    
                span  {
                    display: block;
                    font-weight: $font-weight-medium;
                    font-size: $font-size--text;
                    margin-bottom: 5px;
                    color: $color-main;
                }
            }
    
            .number_posts {
                margin-top: 15px;
                font-size: $font-size--text-small;
                color: $color-second--card;
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .card__content--category {
        flex-direction: column;
        align-items: baseline;

        .card__image-wrapper {
            width: 100%;
            height: 185px;
            min-height: inherit;
        }
    }

}
