.footer {
    background-color: $color-dark;
    padding: 0 0 80px;
    color: $color-white;

    &__container {
        @include flex($wrap: wrap, $alignItems: flex-end);
        gap: 30px;
        padding-top: 60px;
        padding-bottom: 60px;
    }

    &__infos {
        @include flex($direction: column, $justifyContent: center, $alignItems: flex-start);
        gap: 10px;
        width: 350px;
        color: $color-white;
    }

    &__title {
        font-family: $font-family--heading;
        font-weight: $font-weight-semibold;
        text-transform: uppercase;
        @include font-size(20);
        color: $color-white;
    }

    &__buttons {
        @include flex($wrap: wrap, $alignItems: center);
        gap: 30px;
        margin-bottom: 5px;
    }

    &__btn {
        @include flex($alignItems: center);
        gap: 10px;
        min-width: 220px;
        margin: 0;

        svg {
            @include size(20px);
        }
    }

    &__phone {
        color: $color-white;
        background-color: $color-bg--transparent;
        border-color: $color-white;

        svg {
            fill: $color-white;
        }

        &:hover, &:focus {
            color: $color-text;
            background-color: $color-bg--neutral;
            border-color: $color-bg--neutral;

            svg {
                fill: $color-text;
            }
        }
    }

    &__contact {
        color: $color-text;
        background-color: $color-white;
        border-color: $color-white;

        svg {
            fill: $color-text;
        }

        &:hover, &:focus {
            color: $color-white;
            background-color: $color-main;
            border-color: $color-main;

            svg {
                fill: $color-white;
            }
        }
    }

    &__menu {
        &__list {
            @include flex($wrap: wrap, $alignItems: center, $justifyContent: space-between);
            width: 100%;
        }

        &__link {
            font-size: $font-size--text-small;
            color: $color-white;
            text-decoration: underline;
            text-decoration-color: transparent;
            transition: text-decoration $duration $timing;

            &:hover, &:focus {
                color: $color-white;
                text-decoration-color: $color-white;
            }
        } 
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1360
@media screen and (max-width: 1360px) {

    .footer {
        padding-bottom: 60px;

        &__menu {
            &__list {
                justify-content: flex-start;
                row-gap: 10px;
                column-gap: 40px;
            }

            &__item {
                position: relative;
            }
        }
    }

}


// 1040
@media screen and (max-width: $medium) {

    .footer {
        margin-bottom: 80px;
    }

}
