//======================================================================================================
// Bloc Embed code
//======================================================================================================
.bloc-embed_code {
    &__iframe {
        max-width: 100%;
        margin-top: 20px;

        iframe {
            max-width: 100%;
            border: 0;
            background-color: $color-white;
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1360
@media screen and (max-width: 1360px) {
    .bloc-embed_code {
        &__iframe {
            iframe {
                max-height: inherit;
            }
        }
    }
}

// 1200
// @media screen and (max-width: $large) {

//     .bloc-embed_code {
//         &__iframe {
//             iframe {
//                 max-height: 600px;
//             }
//         }
//     }

// }


// 640
// @media screen and (max-width: $small) {

//     .bloc-embed_code {
//         &__iframe {
//             iframe {
//                 max-height: 500px;
//             }
//         }
//     }

// }
