.breadcrumb {
    background-color: $color-dark;

    ul {
        display: block;
        min-height: 60px;
        padding: 18px 0;
    }

    li {
        position: relative;
        display: inline;
        color: $color-white;
        line-height: 1.75;

        a {
            color: $color-white;
            text-decoration: underline;
            text-decoration-color: transparent;
            transition: text-decoration $duration $timing;

            &:hover, &:focus {
                color: $color-white;
                text-decoration-color: $color-white;
            }
        }

        svg {
            position: relative;
            top: 2px;
            display: inline-block;
            width: 20px;
            height: 20px;
            margin: 0 5px;
            fill: $color-white;
            vertical-align: text-top;
        }
    }
}



//======================================================================================================
// Responsive
//======================================================================================================

// 1040
@media screen and (max-width: $medium) {

    .breadcrumb {
        li svg {
            margin: 0;
        } 
    }

}


// 640
@media screen and (max-width: $small) {

    .breadcrumb {
        li svg {
            margin: 0 -1px;
        } 
    }

}
