.post_navigation {
    background-color: $color-bg--transparent;

    &__wrapper {
        @include flex($wrap: wrap, $alignItems: center, $justifyContent: space-between);
        gap: 10px 0;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .return,
    .prev,
    .next {
        a {
            position: relative;
            @include flex($alignItems: center);
            height: 50px;
            padding: 0 30px;
            border: 1px solid $color-bg--transparent;
            border-radius: $btn-border-radius;
            font-family: $btn-font-family;
            font-size: $btn-font-size;
            font-weight: $btn-font-weight;
            color: $color-text;
            text-transform: uppercase;
            transition: border-color $duration $timing;

            &:hover, &:focus {
                border-color: $color-main;
            }
        }
    }

    .return {
        a {
            svg {
                @include size(26px);
                pointer-events: all;
                cursor: pointer;
                fill: $color-text;
                transition: fill $duration $timing;
            }
        }
    }

    .prev, .next {
        // A changer en fonction de la taille du bouton "précédent"
        min-width: 172px;
    }

    .prev {
        .navigation_link {
            @include flex($justifyContent: flex-start);
        }

        a {
            &:before {
                content: "";
                display: inline-block;
                @include size(26px);
                background-color: $color-text;
                mask-image: url( $urlShort + '/ic-nav-arrow-left.svg');
                mask-repeat: no-repeat;
                transition: background-color $duration $timing;
                margin-right: 5px;
            }
        }
    }

    .next {
        .navigation_link {
            @include flex($justifyContent: flex-end);
        }

        a {
            &:after {
                content: "";
                display: inline-block;
                @include size(26px);
                background-color: $color-text;
                mask-image: url( $urlShort + '/ic-nav-arrow-right.svg');
                mask-repeat: no-repeat;
                transition: background-color $duration $timing;
                margin-left: 5px;
            }
        }
    }
}

// for all buttons navigations
.navigation_link {
    column-gap: 5px;
}


//======================================================================================================
// Responsive 
//======================================================================================================

// 1040
@media screen and (max-width: $medium) {

    .post_navigation {

        .prev, .next {
            min-width: 150px;
        }

        .next a, .prev a, .return a {
            height: 50px;
            padding: 0 20px;
        }
    }

}

// 640
@media screen and (max-width: $small) {

    .post_navigation {

        .prev, .next {
            min-width: auto;
        }

        .return {
            @include flex($justifyContent: center);
            order: 1;
            flex: 1 1 100%;
        }

    }
}
