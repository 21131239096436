.search-container {
    display: flex;
    position: relative;
    align-items: center;

    #searchInput {
        height: $search-input-height;
        padding: 24px 70px 24px 24px;
        background-color: $search-input-bg;
        border-color: $search-input-border-color;
        box-sizing: border-box;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;

        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
            display: none;
        }

        &:focus {
            border-color: $search-input-border-color--active;
        }
    }

    .search-svg {
        @include flex($alignItems: center, $justifyContent: center);
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        @include size($search-input-button-width, $search-input-button-height);
        background-color: $search-input-button-bg;
        border: $search-input-button-border;
        border-radius: $border-radius--round;
        cursor: pointer;
        transition: {
            property: background-color, border-color;
            duration: $duration;
            timing-function: $timing;
        }

        svg {
            @include size($search-input-button-width, $search-input-button-height);
            fill: $search-input-button-color;
            transition: fill $duration $timing;
        }

        &:hover, &:focus {
            background-color: $search-input-button-bg--active;
            border-color: $search-input-button-border-color--focus;

            svg {
                fill: $search-input-button-color--active;
            }
        }
    }
}

.searchResult {
    position: relative;
    top: -2px;
    display: flex;
    flex-direction: column;
    background-color: $color-white;
    border-top: 1px solid $color-gray;

    li {
        position: relative;
        background-color: $color-white;
        border-bottom: 1px solid $color-bg--neutral;

        a {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 15px 24px;
            transition: {
                property: color, background-color, padding-left;
                duration: $duration;
                timing-function: $timing;
            }
        }

        .resultCPT {
            position: absolute;
            right: 25px;
            top: 50%;
            pointer-events: none;
            color: $color-gray;
            transform: translate(0,-50%);
            transition: color $duration $timing;
        }

        &.no-results {
            span {
                display: flex;
                width: 100%;
                height: 100%;
                padding: 15px 24px;
                color: $color-gray;
            }
        }

        &:not(.no-results) {
            a:hover, a:focus {
                padding-left: 35px;
                background-color: $color-main;
                color: $color-white;

                &::after {
                    color: $color-white;
                }
            }

            &.selected,
            &:hover, &:focus {
                .resultCPT {
                    color: $color-white;
                }
            }
        }

        &:last-child {
            border-bottom: 0;
        }

        &.selected {
            a {
                padding-left: 35px;
                background-color: $color-main;
                color: $color-white;

                &::after {
                    color: $color-white;
                }
            }
        }

        &.show-all a {
            text-transform: uppercase;
            font-family: $btn-font-family;
            font-size: $btn-font-size;
            font-weight: $font-weight-extrabold;

            &::after {
                content: "\f344";
                position: relative;
                display: flex;
                align-items: center;
                height: 100%;
                margin-left: 10px;
                font: normal 1.125rem/1 dashicons;
                color: $color-text;
                transition: color $duration $timing;
            }
        }
    }
}

body.search-open {
    overflow: hidden;
}

body.search {
    .breadcrumb {
        display: none;
    }
    // .container--search {
    //     padding-top: 30px;
    // }
}

.search-popup {
    position: fixed;
    top: 0;
    z-index: 15;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: $color-daylight;

    .container {
        position: relative;
        height: 100%;
        max-width: $large;
        padding: 20px ($gl-gutter * 0.5);
        margin: 0 auto;

        .search-popup__content {
            position: relative;
            top: 30%;
            display: flex;
            justify-content: center;
            flex-direction: column;   
        }

        label {
            position: relative;
            display: flex;
            align-items: center;
            width: calc(100% - 200px);
            margin: 0 auto 20px auto;
            padding-bottom: 0;
            border: 0;
            font-family: $font-family--heading;
            font-size: $font-size--1;
            font-weight: $font-weight-black;
            color: $color-text;
            text-transform: uppercase;
            letter-spacing: 0.05em;
        }

        .search-container {
            display: flex;
            position: relative;
            align-items: center;
            width: calc(100% - 200px);
            margin: 0 auto;

            #searchInput {
                border-color: $color-bright;
                border-radius: $btn-spe-border-radius;
                border-width: 2px;
                transition: {
                    property: border-color, border-width,  border-radius;
                    duration: $duration;
                    timing-function: $timing;
                }

                &:focus {
                    border-color: $color-main;
                    border-width: 1px;
                }
            }

            &-open {
                #searchInput {
                    border-color: $color-bg--transparent;
                    border-radius: 35px 35px 0 0;
                }
            }
        }

        .searchResult {
            width: calc(100% - 200px);
            left: 100px;
            position: relative;
            border-top: 0;
            top: 0;
            border-radius: 0 0 35px 35px;
            overflow: hidden;
            
            &::before {
                content: '';
                display: block;
                @include absolute($top: -30px, $left: 0);
                z-index: -1;
                @include size(100%, 40px);
                background-color: $color-white;
            }
        }
    }

    .search-close {
        @include flex($alignItems: center, $justifyContent: center);
        cursor: pointer;
        @include absolute($top: 5%, $right: 0);
        @include size(40px);
        margin: 0;
        padding: 0;
        background-color: $color-main;
        border-radius: $border-radius--round;
        transition: {
            property: background-color, border-color;
            duration: $duration;
            timing-function: $timing;
        }
        
        svg {
            @include size(22px);
            fill: $color-white;
            transition: fill $duration $timing;
        }

        &:hover, &:focus {
            background-color: $color-bg--transparent;
            border-color: $color-main;

            svg {
                fill: $color-text;
            }
        }
    }
}


// //======================================================================================================
// // Search Page
// //======================================================================================================
.filter {
    .search-container {
        .searchInput:focus {
            border-color: $search-input-border-color--active !important;
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .search-popup .container {
        max-width: $medium;
        padding: 20px ($gl-gutter-md * 0.5);
    }

    .search-popup .container .search-container,
    .search-popup .container label {
        width: calc(100% - 140px);
    }
    .search-popup .container .searchResult {
        left: 70px;
        width: calc(100% - 140px);
    }

}


// 1040
@media screen and (max-width: $medium) {

    .search-popup .container {
        max-width: 90%;
        padding: 20px ($gl-gutter-sm * 0.5);
    }

    .search-popup .container .search-container,
    .search-popup .container label {
        width: 100%;
    }
    .search-popup .container .searchResult {
        left: 0;
        width: 100%;
    }

    .search-popup .container .search-popup__content {
        top: 20%;
    }

}


// 640
@media screen and (max-width: $small) {

    .searchResult li .resultCPT {
        display: none;
    }
    .search-popup {
        overflow: auto;

        .container {    
            label {
                @include font-size(25);
            }

            &::before, &::after {
                display: none;
            }

            .search-popup__content {
                top: 15%;
            }
        }
    }


}
